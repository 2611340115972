.form {
  background-color: rgba(1,107,223,0.82);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  max-width: 650px;
  width: 100%;
  display: table;
  margin: 0 auto;
}

.form-label {
  color: white;
}

.text-muted {
  color: white !important;
}

.form-check-label {
  color: white;
}

.btn-primary {
  border-color: white;
  display: table;
  margin: 0 auto;
}

html {
  background-image: url("./assets/images/collegeBackgroundImage_by_FreePik.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
  display: table;
  margin: 0 auto;
}

.hr-header {
  border: 1px solid white
}